<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <b>
                  <p class="mb-0 ml-2" style="font-size: 18px">
                    SOLICITUD TIF
                    <span
                      class="badge"
                      :class="
                        form.estado == 1
                          ? 'badge bg-info'
                          : form.estado == 2
                          ? 'badge bg-warning'
                          : form.estado == 3
                          ? 'badge bg-success'
                          : 'badge bg-danger'
                      "
                    >
                      {{ form.nEstado }}
                    </span>
                  </p>
                </b>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">TIF</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card shadow">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario {{ accion }} Solicitud
                <span v-if="form.id"> # {{ form.id }}</span>
              </legend>
              <div class="row">
                <div class="col-sm-9">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="fecha">Fecha requerimiento solicitud</label>
                      <input
                        type="date"
                        :min="hoy"
                        class="form-control form-control-sm shadow-sm"
                        id="fecha"
                        v-model="form.fecha_solicitud"
                        v-on:input="$v.form.fecha_solicitud.$touch"
                        :class="
                          $v.form.fecha_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="form.estado == 2 || bloquear"
                        @change="validaFecha()"
                      />
                      <div
                        v-if="
                          (!$v.form.fecha_solicitud.$dirty ||
                            $v.form.fecha_solicitud.$invalid) &&
                            form.fecha_solicitud == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="observaciones">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm shadow-sm"
                        v-model="form.observaciones"
                        v-on:input="$v.form.observaciones.$touch"
                        :class="
                          $v.form.observaciones.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="form.estado == 2 || bloquear"
                      >
                      </textarea>
                      <div
                        v-if="
                          (!$v.form.observaciones.$dirty ||
                            $v.form.observaciones.$invalid) &&
                            form.observaciones == ''
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="justificacion">Justificación</label>
                      <textarea
                        class="form-control form-control-sm  shadow-sm"
                        id="justificacion"
                        v-model="form.justificacion"
                        :disabled="form.estado == 2 || bloquear"
                      >
                      </textarea>
                    </div>
                    <div
                      class="form-group col-md-5"
                      v-if="this.form.id && !$v.gerente.$invalid"
                    >
                      <label for="gerente">Gerente Area</label>
                      <v-select
                        class="p-0 mr-3 p-0 shadow-sm"
                        :class="[
                          $v.gerente.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        disabled
                        v-model="gerente"
                        placeholder="Gerente Area"
                        label="funcionario"
                        :options="listasForms.gerentes"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-3">
                      <label for="estado">Tipo Solicitud</label>
                      <select
                        class="form-control form-control-sm 
                        shadow-sm"
                        :class="
                          $v.form.tipo_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        v-model="form.tipo_solicitud"
                        :disabled="bloquear"
                        @change="validarTipoSolicitud()"
                      >
                        <option value="">seleccione...</option>
                        <option
                          v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                          :key="tipo_solicitud.numeracion"
                          :value="tipo_solicitud.numeracion"
                        >
                          {{ tipo_solicitud.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-3" v-if="this.form.id">
                      <label for="estado">Estado</label>
                      <select
                        class="form-control form-control-sm 
                        shadow-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        v-model="form.estado"
                        :disabled="bloquear"
                      >
                        <option value="">seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-3">
                      <button
                        class="btn bg-primary  shadow"
                        v-show="!$v.form.$invalid"
                        @click="save()"
                        v-if="
                          (!bloquear &&
                            $store.getters.can(
                              'funcionariofrontera.tifSolicitudes.edit'
                            )) ||
                            (!bloquear &&
                              $store.getters.can(
                                'funcionariofrontera.tifSolicitudes.create'
                              ))
                        "
                      >
                        <i class="fas fa-paper-plane"></i>
                        <div>
                          Guardar
                        </div>
                      </button>
                      <button
                        class="btn bg-secondary ml-1  shadow"
                        @click="validacionVolver()"
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div
                    class="card card-outline card-navy mr-0 col-md-12 p-2 shadow"
                  >
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title">
                        <h6>
                          <i class="fas fa-users"></i>
                          Datos de Usuario
                        </h6>
                      </h3>
                    </div>
                    <div>
                      <div class="form-group">
                        <label for="user">Usuario</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="user"
                          v-model="form.user.name"
                          :class="
                            $v.form.user.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="correo">Correo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="correo"
                          v-model="form.user.email"
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="celular">Celular</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="celular"
                          v-model="form.user.cel"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <!-- DETALLE -->
            <div v-show="form.id != null">
              <fieldset class="well card shadow">
                <legend class="well-legend text-bold bg-frontera text-light">
                  <div class="form-group m-1">
                    {{ form.tipo_solicitud == 1 ? "Detalles" : "Detalle" }}
                    <button
                      class="btn btn-sm bg-gradient-success float-right shadow-sm"
                      data-toggle="modal"
                      data-target="#modal-form-detalle-solicitud"
                      @click="llenarModal('Crear', null)"
                      v-if="
                        !bloquear &&
                          $store.getters.can(
                            'funcionariofrontera.tifSolicitudes.createDetalle'
                          ) &&
                          ((form.tipo_solicitud == 2 &&
                            form.detalles.length < 1) ||
                            form.tipo_solicitud == 1)
                      "
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </legend>
                <DetSolicitudForm ref="DetSolicitudForm" />
                <div v-for="(detalle, index) in form.detalles" :key="index">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-navy card-outline collapsed-show">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <i class="fas fa-clipboard-list"></i> Detalle
                          <span class="badge bg-gradient-navy">
                            {{ index + 1 }}
                          </span>
                        </h3>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-solicitud"
                            @click="llenarModal('Editar', detalle)"
                            v-if="
                              !bloquear &&
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.editDetalle'
                                )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn btn-sm bg-gradient-danger"
                            @click="destroy(detalle.id)"
                            v-if="
                              !bloquear &&
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.deleteDetalle'
                                )
                            "
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="card-body table-responsive">
                          <table
                            class="table table-bordered table-striped table-hover table-sm p-2 shadow"
                          >
                            <thead class="bg-gradient-secondary">
                              <tr>
                                <th class="text-center">Tipo Vehículo</th>
                                <th class="text-center">Cantidad Vehículos</th>
                                <th class="text-center">Empresa</th>
                                <th class="text-center">Ruta</th>
                                <th class="text-center">Turno</th>
                                <th class="text-center">Producto</th>
                                <th class="text-center">Accesorios</th>
                                <th class="text-center">Bloque</th>
                                <th class="text-center">Operación</th>
                              </tr>
                            </thead>
                            <tbody id="tbody">
                              <tr>
                                <td class="text-center">
                                  {{ detalle.tipo_vh.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.cantidad_vehiculos }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.empresa.razon_social }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.ruta.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.turno.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.producto.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.accesorios }}
                                </td>
                                <td class="text-center">
                                  {{
                                    detalle.bloque ? detalle.bloque.nombre : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  {{
                                    detalle.operacion
                                      ? detalle.operacion.Noperacion
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <!-- Contables -->
              <div
                v-if="
                  form.id != null &&
                    form.detalles.length > 0 &&
                    form.tipo_solicitud == 2
                "
              >
                <fieldset class="well card shadow">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    <div class="form-group m-1">
                      Asignación Cargos Contables
                    </div>
                  </legend>
                  <div class="col-md-12">
                    <div class="card card-info card-outline ">
                      <div class="card-header pt-2 pb-2">
                        <div class="row justify-content-between">
                          <div class="form-group col-md-10">
                            <label>Gerente Area</label>
                            <div class="row">
                              <div class="col-md-6">
                                <v-select
                                  class="p-0 mr-3 p-0 rounded shadow-sm"
                                  :class="[
                                    $v.gerente.$invalid
                                      ? 'border border-danger'
                                      : 'border border-success',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="gerente"
                                  :disabled="bloquear"
                                  placeholder="Gerente Area"
                                  label="funcionario"
                                  :options="listasForms.gerentes"
                                  @input="updateGerente()"
                                  v-if="
                                    $store.getters.can(
                                      'funcionariofrontera.tifSolicitudes.updateGerente'
                                    )
                                  "
                                ></v-select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-1">
                            <div class="btn-group float-right">
                              <button
                                v-if="
                                  !bloquear &&
                                    $store.getters.can(
                                      'funcionariofrontera.tifSolicitudes.createContable'
                                    )
                                "
                                type="button"
                                class="btn bg-gradient-navy text-white"
                                @click="addContable()"
                                :disabled="
                                  estado_contable == 1 ||
                                    totalPresupuesto == 100 ||
                                    $v.gerente.$invalid
                                "
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                              <!-- </div> -->
                              <!-- <div > -->
                              <button
                                v-if="
                                  tifContables.length > 0 &&
                                    !bloquear &&
                                    $store.getters.can(
                                      'funcionariofrontera.tifSolicitudes.deleteContables'
                                    )
                                "
                                type="button"
                                class="btn bg-gradient-danger text-white"
                                @click="destroyContable('completo')"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!$v.gerente.$invalid"
                        class="card-body table-responsive p-5"
                      >
                        <div
                          v-if="!$v.gerente.$invalid"
                          class="col-md-12 mb-0 text-center mb-5"
                          :class="
                            totalPresupuesto == 100
                              ? 'text-success'
                              : totalPresupuesto < 100
                              ? 'text-warning'
                              : 'text-danger'
                          "
                        >
                          <h4>Porcentaje Total: {{ totalPresupuesto }}%</h4>
                        </div>
                        <table
                          class="table  table-bordered table-striped table-hover table-sm p-2 shadow"
                        >
                          <thead class="bg-gradient-secondary">
                            <tr>
                              <th class="text-center">Area</th>
                              <th class="text-center">Tipo Presupuesto</th>
                              <th class="text-center">CECO/AFE/G&A</th>
                              <th class="text-center text-nowrap">
                                Responsable
                              </th>
                              <th class="text-center">Activo Producción</th>
                              <th class="text-center" style="min-width: 12em">
                                % de la operación para cargar este CECO/AFE
                              </th>
                              <th style="width: 10em;" v-if="!bloquear">
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody id="tbody">
                            <tr
                              v-for="(tifContable, index) in tifContables"
                              :key="tifContable.id"
                            >
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <v-select
                                    class="form-control form-control-sm mr-3 p-0"
                                    :class="
                                      $v.contable.area.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="area"
                                    placeholder="Areas"
                                    label="descripcion"
                                    :options="listasForms.areas"
                                    @input="getSelectArea()"
                                  ></v-select>
                                </div>
                                <div v-else>
                                  {{ tifContable.nArea }}
                                </div>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <v-select
                                    class="form-control form-control-sm mr-3 p-0"
                                    :class="[
                                      $v.contable.tipo_presupuesto.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : '',
                                    ]"
                                    v-model="contable.tipoPresupuesto"
                                    placeholder="Tipo Presupuesto"
                                    label="descripcion"
                                    :options="listasForms.tipos_presupuesto"
                                    @input="[resetCecoAfe(), getCecosAfes()]"
                                  ></v-select>
                                </div>
                                <span
                                  v-else
                                  class="badge"
                                  :class="
                                    tifContable.tipo_presupuesto == 1
                                      ? 'bg-info'
                                      : tifContable.tipo_presupuesto == 2
                                      ? 'bg-navy'
                                      : 'bg-lime'
                                  "
                                >
                                  {{
                                    tifContable.tipo_presupuesto
                                      ? tifContable.nTipoPresupuesto
                                      : null
                                  }}
                                </span>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <v-select
                                    v-model="ceco_afe"
                                    placeholder="Ceco Afe"
                                    label="codigo_contable"
                                    :options="listasForms.ceco_afes"
                                    class="form-control form-control-sm mr-3 p-0"
                                    :class="
                                      $v.contable.ceco_afe_id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    @input="getSelectCeco()"
                                    :disabled="
                                      contable.tipo_presupuesto == null
                                    "
                                  ></v-select>
                                </div>
                                <div v-else>
                                  {{
                                    tifContable.ceco_afe
                                      ? tifContable.ceco_afe.codigo_contable
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <v-select
                                    class="form-control form-control-sm p-0 mr-3 p-0"
                                    :class="[
                                      $v.contable.responsable_id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : '',
                                    ]"
                                    v-model="responsable"
                                    placeholder="Nombre Responsable"
                                    label="funcionario"
                                    :options="listasForms.responsables"
                                    @input="getSelectResposable"
                                  ></v-select>
                                </div>
                                <div v-else>
                                  <div
                                    v-if="
                                      tifContable.responsable &&
                                        tifContable.responsable.nombres
                                    "
                                  >
                                    {{
                                      tifContable.responsable.nombres
                                        ? tifContable.responsable.nombres +
                                          " " +
                                          tifContable.responsable.apellidos
                                        : ""
                                    }}
                                  </div>

                                  <div v-else>
                                    {{
                                      tifContable.responsable
                                        ? tifContable.responsable.funcionario
                                        : ""
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <v-select
                                    class="form-control form-control-sm mr-3 p-0"
                                    :class="[
                                      $v.contable.activo_produccion.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : '',
                                    ]"
                                    v-model="activo_produccion"
                                    placeholder="Tipo Presupuesto"
                                    label="descripcion"
                                    :options="listasForms.activosProduccion"
                                    @input="getSelectActivosProduccion"
                                  ></v-select>
                                </div>
                                <div v-else>
                                  {{ tifContable.nActivoProduccion }}
                                </div>
                              </td>
                              <td class="text-center">
                                <div
                                  v-if="
                                    id_actualizar_contable == index &&
                                      estado_contable == 2
                                  "
                                >
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.contable.porcentaje.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="contable.porcentaje"
                                  />
                                </div>
                                <div v-else>{{ tifContable.porcentaje }}</div>
                              </td>
                              <td v-if="!bloquear">
                                <div
                                  class="btn-group text-center shadow"
                                  v-if="estado_contable != 2"
                                >
                                  <button
                                    type="button"
                                    class="btn bg-gradient-navy"
                                    :disabled="estado_contable != -1"
                                    @click="editContable(index)"
                                    v-if="
                                      !bloquear &&
                                        $store.getters.can(
                                          'funcionariofrontera.tifSolicitudes.editContable'
                                        )
                                    "
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    class="btn bg-gradient-danger"
                                    :disabled="estado_contable != -1"
                                    @click="destroyContable(tifContable.id)"
                                    v-if="
                                      !bloquear &&
                                        $store.getters.can(
                                          'funcionariofrontera.tifSolicitudes.deleteContable'
                                        )
                                    "
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                                <div
                                  class="btn-group float-right"
                                  v-if="
                                    estado_contable == 2 &&
                                      id_actualizar_contable == index
                                  "
                                >
                                  <button
                                    type="button"
                                    class="btn bg-success"
                                    v-show="!$v.contable.$invalid"
                                    @click="saveUpdateContable(index)"
                                    v-if="
                                      $store.getters.can(
                                        'funcionariofrontera.tifSolicitudes.editContable'
                                      )
                                    "
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn bg-danger"
                                    @click="cancelContable()"
                                  >
                                    <i class="fas fa-ban"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr v-if="estado_contable == 1">
                              <td>
                                <v-select
                                  class="form-control form-control-sm mr-3 p-0"
                                  v-model="area"
                                  :class="
                                    $v.contable.area.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  placeholder="Areas"
                                  label="descripcion"
                                  :options="listasForms.areas"
                                  @input="getSelectArea()"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  class="form-control form-control-sm mr-3 p-0"
                                  :class="[
                                    $v.contable.tipo_presupuesto.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="contable.tipoPresupuesto"
                                  placeholder="Tipo Presupuesto"
                                  label="descripcion"
                                  :options="listasForms.tipos_presupuesto"
                                  @input="[resetCecoAfe(), getCecosAfes()]"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  v-model="ceco_afe"
                                  placeholder="Ceco Afe"
                                  label="codigo_contable"
                                  :options="listasForms.ceco_afes"
                                  class="form-control form-control-sm mr-3 p-0"
                                  :class="
                                    $v.contable.ceco_afe_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @input="getSelectCeco()"
                                  :disabled="contable.tipo_presupuesto == null"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  class="form-control form-control-sm p-0 mr-3 p-0"
                                  :class="[
                                    $v.contable.responsable_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="responsable"
                                  placeholder="Nombre Responsable"
                                  label="funcionario"
                                  :options="listasForms.responsables"
                                  @input="getSelectResposable"
                                ></v-select>
                              </td>
                              <td>
                                <v-select
                                  class="form-control form-control-sm p-0 mr-3 p-0"
                                  :class="[
                                    $v.contable.activo_produccion.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="activo_produccion"
                                  placeholder="Tipo Presupuesto"
                                  label="descripcion"
                                  :options="listasForms.activosProduccion"
                                  @input="getSelectActivosProduccion"
                                ></v-select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="form-control form-control-sm p-0 mr-3 p-0"
                                  id="porcentaje"
                                  v-model="contable.porcentaje"
                                  :class="
                                    $v.contable.porcentaje.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </td>
                              <td v-if="!bloquear">
                                <div class="btn-group float-right">
                                  <button
                                    class="btn bg-success"
                                    v-show="!$v.contable.$invalid"
                                    @click="saveContable()"
                                    v-if="
                                      $store.getters.can(
                                        'funcionariofrontera.tifSolicitudes.createContable'
                                      )
                                    "
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn bg-danger"
                                    @click="cancelContable()"
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="form-group">
              <button class="btn btn-default ml-1" @click="validacionVolver()">
                <i class="fas fa-reply"></i>
                <div>Volver</div>
              </button>
              <button
                class="btn bg-gradient-navy text-white"
                v-if="
                  form.id && form.tipo_solicitud == 1 && totalPresupuesto == 0
                "
                @click="save(true)"
                :disabled="bloquear && form.estado != 1"
              >
                <div><i class="fas fa-file-upload"></i></div>
                <div>Guardar</div>
              </button>
              <button
                class="btn bg-frontera text-white"
                v-if="
                  form.id &&
                    totalPresupuesto == 100 &&
                    form.tipo_solicitud == 2 &&
                    $store.getters.can(
                      'funcionariofrontera.tifSolicitudes.enviarCorreos'
                    )
                "
                @click="saveTotal()"
                :disabled="bloquear && form.estado != 1"
              >
                <div><i class="fas fa-mail-bulk"></i></div>
                <div>Enviar</div>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import DetSolicitudForm from "./DetSolicitudForm";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "SolicitudForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    DetSolicitudForm,
    vSelect,
  },

  data() {
    return {
      cargando: false,
      bloquear: false,
      user: {},
      file: null,
      accion: "",
      act: "",
      metodo: "",
      userRol: false,
      hoy: moment().format("YYYY-MM-DD"),
      btnDisabled: false,
      form: {
        id: null,
        user_id: null,
        user: {},
        fecha_solicitud: null,
        observaciones: null,
        justificacion: null,
        estado: null,
        detalles: [],
        autorizar: false,
      },
      listasForms: {
        operacionesSitio: [],
        ceco_afes: [],
        tipos_solicitudes: [],
        clases_solicitudes: [],
        tipos_emergencias: [],
        vicepreseidentes: [],
        sitios: [],
        bloques: [],
        empresas: [],
        tipos_tarifas: [],
        tipos_vehiculos: [],
        rutas: [],
        productosLiquidos: [],
        turnos: [],
        areas: [],
        tipos_presupuesto: [],
        responsables: [],
        gerentes: [],
        activosProduccion: [],
        estados: [],
      },
      guardar: 0,
      message: "",
      //Variables Contable
      area: [],
      gerente: [],
      responsable: [],
      activo_produccion: [],
      contable: {},
      ceco_afe: {},
      tifContables: [],
      id_actualizar_contable: -1,
      estado_contable: -1,
      totalPresupuesto: 0,
    };
  },
  validations() {
    if (this.form.id) {
      return {
        form: {
          user_id: {
            required,
          },
          user: {
            required,
          },
          fecha_solicitud: {
            required,
          },
          observaciones: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          estado: {
            required,
          },
        },
        //Validación de Contables
        contable: {
          tipo_presupuesto: {
            required,
          },
          ceco_afe_id: {
            required,
          },
          codigo_ceco_afe: {
            required,
          },
          porcentaje: {
            required,
          },
          area: {
            required,
          },
          activo_produccion: {
            required,
          },
          responsable_id: {
            required,
          },
        },

        gerente: {
          required,
        },
      };
    } else {
      return {
        form: {
          user_id: {
            required,
          },
          user: {
            required,
          },
          fecha_solicitud: {
            required,
          },
          observaciones: {
            required,
          },
          tipo_solicitud: {
            required,
          },
        },
      };
    }
  },
  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.accion ? this.accion : this.$route.params.accion;
      let id = this.form.id ? this.form.id : this.$route.params.id;
      if (this.accion == "Editar") {
        this.metodo = "PUT";
        this.contable.tif_solicitud_id = this.$route.params.id;
        await axios
          .get("/api/funcionariosFrontera/tifSolicitudes/" + id)
          .then(async (response) => {
            this.form = await response.data;
            this.gerente = this.listasForms.gerentes.find(
              (funcionario) => funcionario.id === this.form.gerente_area_id
            );
            //contables
            this.tifContables = [];
            this.form.contables.forEach((cont) => {
              this.tifContables.push({
                id: cont.id,
                tif_det_solicitud_id: cont.tif_det_solicitud_id,
                tipo_presupuesto: cont.tipo_presupuesto,
                nTipoPresupuesto: cont.nTipoPresupuesto,
                area: cont.area,
                nArea: cont.nArea,
                activo_produccion: cont.activo_produccion,
                nActivoProduccion: cont.nActivoProduccion,
                responsable: cont.responsable,
                responsable_id: cont.responsable.id,
                ceco_afe_id: cont.ceco_afe.id,
                codigo_ceco_afe: cont.ceco_afe.codigo_contable,
                porcentaje: parseFloat(cont.porcentaje),
                tipoPresupuesto: this.listasForms.tipos_presupuesto.find(
                  (e) => e.numeracion == cont.tipo_presupuesto
                ),
                ceco_afe: cont.ceco_afe,
              });
            });
            this.calcularContable();
          });
        this.bloquear = this.form.estado != 1 ? true : false;
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.form.user = await this.$store.state.user;
        this.form.user_id = await this.$store.state.user.id;
      }
      await this.getUser();
      await this.userRoles();
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    validaFecha() {
      if (this.form.tipo_solicitud == 1 && this.form.fecha_solicitud) {
        let fecha_hoy = moment(this.hoy);
        let fecha_solicitud = moment(this.form.fecha_solicitud);
        if (
          fecha_solicitud.diff(fecha_hoy, "days") < 2 &&
          this.form.clase_solicitud == 2
        ) {
          this.form.fecha_solicitud = null;
          this.$swal({
            icon: "error",
            title:
              "Las Solicitudes Normales deben hacerce con más de un 48 horas de antelación.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/220").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    async getTipoClase() {
      await axios.get("/api/lista/183").then((response) => {
        this.listasForms.clases_solicitudes = response.data;
      });
    },

    async getTipoEmergencia() {
      await axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    save(autorizar = false) {
      if (!this.$v.form.$invalid) {
        if (autorizar) {
          this.form.autorizar = true;
        }
        this.form.gerente_area_id = this.gerente ? this.gerente.id : null;
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/tifSolicitudes",
          data: this.form,
        })
          .then((response) => {
            this.form.id = response.data.id;
            this.contable.tif_solicitud_id = response.data.id;
            this.bloquear = response.data.estado != 1 ? true : false;
            this.accion = "Editar";
            this.getIndex();
            this.$swal({
              text: "Se guardó la solicitud...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async getSitios() {
      await axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: this.form.bloque_id },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        });
    },

    seleccionarSitio() {
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      } else {
        this.form.sitio_id = null;
      }
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.form.bloque) {
        this.form.bloque_id = this.form.bloque.id;
        this.getSitios();
      } else {
        this.form.bloque_id = null;
      }
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = (await this.user.funcionario[0])
        ? this.user.funcionario[0].nArea
        : "";
    },

    validacionVolver() {
      return this.$router.replace("/FuncionariosFrontera/Tif/Solicitudes");
    },

    userRoles() {
      this.userRol = false;
      let usuario = this.$store.state.user;
      for (let i = 0; i < usuario.roles.length; i++) {
        let roles = usuario.roles[i];
        switch (roles.id) {
          case 44:
            this.userRol = true;
            break;
          default:
            break;
        }
      }
    },

    async getEmpresa() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    async getTipoVh() {
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    async getRutas() {
      await axios.get("/api/tif/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    async getProductosLiquidos() {
      await axios
        .get("/api/admin/productos/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.productosLiquidos = response.data;
        });
    },

    async getTipoTarifa() {
      await axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipos_tarifas = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/214").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTurnos() {
      await axios
        .get("/api/admin/turnos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getTipoPresupuesto() {
      await axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipos_presupuesto = response.data;
      });
    },

    async getResponsables() {
      await axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
        this.listasForms.gerentes = this.listasForms.responsables.filter(
          (funcionario) => funcionario.gerente_area === 1
        );
      });
    },

    getActivosProduccion() {
      axios.get("/api/lista/137").then((response) => {
        this.listasForms.activosProduccion = response.data;
      });
    },

    llenarModal(accion, detalle) {
      let data = {
        accion: accion,
        detalle: detalle,
      };
      this.$refs.DetSolicitudForm.getIndex(data);
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este detalle?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/funcionariosFrontera/tifSolicitudes/detalle/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    //Metodos Contables
    async addContable() {
      await this.limpiarContable();
      this.estado_contable = 1;
    },

    async limpiarContable() {
      this.contable = {
        id: null,
        tif_solicitud_id: this.form.id,
        tipo_presupuesto: null,
        ceco_afe_id: null,
        codigo_ceco_afe: null,
        porcentaje: null,
        tif_det_solicitud_id: null,
        tipoPresupuesto: null,
        ceco_afe: null,
        area: null,
        activo_produccion: null,
        responsable_id: null,
        gerente_area_id: null,
      };
      this.responsable = {};
      this.activo_produccion = {};
      this.area = {};
      this.ceco_afe = {};
    },

    getSelectActivosProduccion() {
      this.contable.activo_produccion = null;
      this.contable.nActivoProduccion = null;
      if (this.activo_produccion) {
        this.contable.activo_produccion = this.activo_produccion.numeracion;
        this.contable.nActivoProduccion = this.activo_produccion.descripcion;
      }
    },

    getSelectResposable() {
      this.contable.responsable_id = null;
      if (this.responsable) {
        this.contable.responsable_id = this.responsable.id;
        this.contable.responsable = this.responsable;
      }
    },

    getSelectArea() {
      this.contable.area = null;
      this.contable.nArea = null;
      if (this.area) {
        this.contable.area = this.area.numeracion;
        this.contable.nArea = this.area.descripcion;
      }
    },

    resetCecoAfe() {
      this.contable.ceco_afe = null;
      this.contable.ceco_afe_id = null;
      this.ceco_afe = null;
    },

    async getCecosAfes() {
      this.contable.tipo_presupuesto = null;
      if (this.contable.tipoPresupuesto) {
        this.contable.tipo_presupuesto = this.contable.tipoPresupuesto.numeracion;
        this.contable.nTipoPresupuesto = this.contable.tipoPresupuesto.descripcion;
        await axios
          .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
            params: {
              tipo_presupuesto: this.contable.tipo_presupuesto,
              linea_negocio_id: 3,
            },
          })
          .then(async (response) => {
            this.listasForms.ceco_afes = response.data;
            if (this.listasForms.ceco_afes.length == 0) {
              await this.$swal({
                icon: "warning",
                title: "Advertencia!!",
                text:
                  "No se encontró datos para el Tipo Presupuesto. Por favor validar.!",
                timer: 4000,
                backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
                timerProgressBar: true,
                confirmButtonText: "Aceptar",
                customClass: {
                  popup: "swal-custom-popup", // Custom class for swal popup
                  container: "my-swal",
                },
                didOpen: () => {
                  // Esto asegura que el SweetAlert se muestre sobre la modal
                  const modal = document.getElementById(
                    "modal-form-detalle-solicitud"
                  );
                  if (modal) {
                    modal.style.zIndex = 10700; // Un valor mayor que la alerta
                  }
                },
              });
            }
          });
      }
    },

    getSelectCeco() {
      this.contable.ceco_afe_id = null;
      if (this.ceco_afe) {
        this.contable.ceco_afe = { ...this.ceco_afe };
        this.contable.ceco_afe_id = this.ceco_afe.id;
        this.contable.codigo_ceco_afe = this.ceco_afe.codigo_contable;
      }
    },

    async saveContable() {
      let me = this;
      me.contable.gerente_area_id = me.gerente ? me.gerente.id : null;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.contable.porcentaje) <= 100 &&
        me.contable.porcentaje > 0
      ) {
        axios({
          method: "post",
          url: "/api/funcionariosFrontera/tifSolicitudes/contables",
          data: me.contable,
        })
          .then((response) => {
            this.getIndex();
            this.$swal({
              text: "Se guardó la Cargo Contable...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });

        me.estado_contable = -1;
        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "my-swal",
          },
        });
      }
    },

    async calcularContable() {
      let totalPresupuesto = 0;
      this.tifContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },

    async editContable(index) {
      this.limpiarContable();
      this.ceco_afe = this.tifContables[index].ceco_afe;
      this.ceco_afe;
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.contable = { ...this.tifContables[index] };

      this.contable.tipoPresupuesto = this.listasForms.tipos_presupuesto.find(
        (item) => item.numeracion === this.contable.ceco_afe.tipo_presupuesto
      );

      this.area = this.listasForms.areas.find(
        (item) => item.numeracion === this.contable.area
      );

      this.activo_produccion = this.listasForms.activosProduccion.find(
        (item) => item.numeracion === this.contable.activo_produccion
      );

      this.responsable = this.listasForms.responsables.find(
        (item) => item.id === this.contable.responsable_id
      );

      this.ceco_afe_obj = { ...this.tifContables[index].ceco_afe_obj };
      await this.getCecosAfes();
    },

    async saveUpdateContable(index) {
      let me = this;
      let sumaPorcentaje = 0;
      if (index != null) {
        this.tifContables.forEach((element, key) => {
          if (key !== index) {
            sumaPorcentaje += parseFloat(element.porcentaje);
          }
        });
      } else {
        this.tifContables.forEach((element) => {
          sumaPorcentaje += parseFloat(element.porcentaje);
        });
      }
      let total_presupuesto =
        sumaPorcentaje + parseFloat(this.contable.porcentaje);
      if (total_presupuesto > 100) {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "my-swal",
          },
        });
      } else {
        axios({
          method: "put",
          url: "/api/funcionariosFrontera/tifSolicitudes/contables",
          data: me.contable,
        })
          .then((response) => {
            this.getIndex();
            this.$swal({
              text: "Se guardó la Cargo Contable...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });

        this.estado_contable = -1;
        this.id_actualizar_contable = -1;
        await me.calcularContable();
      }
    },

    async destroyContable(index) {
      if (index == "completo") {
        this.$swal({
          title: "Quitar Cargos Contables.",
          text: "Está seguro de quitar los cargos contables?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            container: "my-swal",
          },
          confirmButtonText: "Si, Quitar!",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(
                "/api/funcionariosFrontera/tifSolicitudes/contables/" +
                  this.form.id
              )
              .then((response) => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "Se elimino el item exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
            this.asignacionContable = null;
            this.calcularContable();
          }
        });
      } else {
        this.$swal({
          title: "Quitar Cargo Contable.",
          text: "Está seguro de quitar este cargo contable?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
          customClass: {
            container: "my-swal",
          },
        }).then((result) => {
          if (result.value) {
            axios
              .delete(
                "/api/funcionariosFrontera/tifSolicitudes/contable/" + index
              )
              .then((response) => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "Se elimino el item exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
            this.calcularContable();
          }
        });
      }
    },

    async cancelContable() {
      this.limpiarContable();
      this.estado_contable = -1;
    },

    updateGerente() {
      let data = {
        id: this.form.id,
        gerente_area_id: this.gerente ? this.gerente.id : null,
      };
      if (this.form.gerente_area_id) {
        this.$swal({
          title: "Esta seguro de actualizar el Gerente Area?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Actualizar!",
        }).then((result) => {
          if (result.value) {
            axios({
              method: "put",
              url: "/api/funcionariosFrontera/tifSolicitudes/updateGerente",
              data: data,
            }).then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se actualizó el gerente exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          }
        });
      } else {
        axios({
          method: "put",
          url: "/api/funcionariosFrontera/tifSolicitudes/updateGerente",
          data: data,
        }).then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se actualizó el gerente exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },

    saveTotal() {
      this.$swal({
        title: "Esta seguro de enviar Correos?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios({
            method: "post",
            url: "/api/funcionariosFrontera/tifSolicitudes/enviarCorreos",
            data: { id: this.form.id },
          }).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Correos enviados exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
          this.cargando = false;
        }
      });
    },

    validarTipoSolicitud() {
      if (this.form.id && this.form.gerente_area_id) {
        this.form.tipo_solicitud = 2;
        this.$swal({
          icon: "error",
          title:
            "No es posible cambiar el tipo de solicitud, ya que tiene un gerente de área asignado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTipoSolicitud();
    await this.getEstados();
    await this.getTipoClase();
    await this.getTipoEmergencia();
    await this.getBloques();
    await this.getTipoVh();
    await this.getEmpresa();
    await this.getRutas();
    await this.getProductosLiquidos();
    await this.getTipoTarifa();
    await this.getTurnos();
    await this.getAreas();
    await this.getTipoPresupuesto();
    await this.getResponsables();
    await this.getActivosProduccion();
    await this.getIndex();
    this.cargando = false;
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
